import { defaultTo, get, omitBy, isNil, isFinite, keyBy } from 'lodash';
import { ClientConfig } from 'client/configuration';
import { getQuery } from 'client/utils/location';
import {
  getMultiOfferResultsLink,
  getOfferDetailsPageLink,
} from 'site-modules/shared/utils/multi-offer-link-constructor';
import { venomHistory } from 'client/utils/history/venom-history';
import { getIsAppraisalCheckoutEligible } from 'site-modules/shared/utils/appraisal/appraisal-checkout';
import { ESTIMATED_OFFER_DATA_KEYS } from 'site-modules/shared/constants/appraisal/estimate';
import { SOLICITATION_SUPPORTED_PARTNERS } from 'site-modules/shared/constants/multi-offer/multi-offer-config';
import { PARTNERS } from 'site-modules/shared/constants/multi-offer/partners';

const { ESTIMATE, BELOW_AVERAGE, AVERAGE, ABOVE_AVERAGE, EXCELLENT } = ESTIMATED_OFFER_DATA_KEYS;

/**
 * Returns whether the given mileage meets the cutoff criteria of the specified partner.
 *
 * @param {String} mileage mileage to check.
 * @param {Object} multiOfferPrequalification prequalification object containing partners' cutoff mileages.
 * @param {String} partner the partner to check the mileage for, e.g. 'carwiser3'.
 * @returns {Boolean}
 */
export const meetsMileageCriteria = (mileage, multiOfferPrequalification, partner) => {
  const mileageInt = parseInt(mileage, 10);
  const partnerMileageCutoff = get(multiOfferPrequalification, [
    'partners',
    partner,
    'solicitation',
    'criteria',
    'cutoffMileage',
  ]);
  let mileageCutoffInt = partnerMileageCutoff;
  if (typeof partnerMileageCutoff === 'string') {
    mileageCutoffInt = parseInt(partnerMileageCutoff, 10);
  }
  return !!mileageCutoffInt && mileageInt <= mileageCutoffInt;
};

/**
 * Returns if the given partner is prequalified for the Multi-Offer experience.
 * Includes both backend and frontend checks.
 *
 * @param {String} partner the partner to check.
 * @param {Object} multiOfferPrequalification the Prequalify API response.
 * @param {String} mileage the user-provided mileage, used for the frontend check.
 * @returns {Boolean}
 */
export const isPartnerPrequalified = (partner, multiOfferPrequalification, mileage) => {
  if (get(multiOfferPrequalification, ['partners', partner, 'solicitation', 'isPrequalified']) !== true) {
    return false;
  }

  return meetsMileageCriteria(mileage, multiOfferPrequalification, partner);
};

/**
 * Returns array of solicitation support partners that user prequalifies for the Multi-Offer experience.
 *
 * @param {Object} multiOfferPrequalification the Prequalify API response.
 * @param {String} mileage the user-provided mileage, used for the frontend check.
 *
 * @returns {Array} prequalified partners
 */
export const getPrequalifiedPartners = ({ multiOfferPrequalification, mileage }) =>
  SOLICITATION_SUPPORTED_PARTNERS.filter(partner =>
    isPartnerPrequalified(partner, multiOfferPrequalification, mileage)
  );

export const WS_EVENTS = {
  INITIAL_PUSH: 'initialPushModsRecord',
  UPDATE_PUSH: 'updatePushModsRecord',
};
export const WS_CONFIG_MULTI_OFFER_PATH_KEY = 'multiOfferPath';

/**
 * Get websocket configuration from client configuration.
 * DEV and SANDBOX environments is explicitly specified in the
 * environment config file, where other environments uses "location.host"
 * @param {String} pathKey key for the WebSocket path value
 * @returns {Object}
 */
export const getWebSocketConfig = pathKey => {
  const { host, [pathKey]: path } = ClientConfig.get('edmundsMultiOfferWebSocket');
  const webSocketHost = host || window.location.host;
  return {
    path,
    origin: `wss://${webSocketHost}`,
  };
};

const getModsEstimateObject = partnerAppraisal => {
  if (get(partnerAppraisal, 'valuation')) {
    return undefined;
  }

  try {
    const cpsMetadataRaw = get(partnerAppraisal, 'metaData.cpsMetadata');
    const cpsMetadata = JSON.parse(cpsMetadataRaw);

    const valuation = parseInt(get(cpsMetadata, ESTIMATE), 10);

    const estimateByCategory = keyBy(get(cpsMetadata, 'estimateCategory'), 'category');
    const belowAverage = parseInt(get(estimateByCategory, [BELOW_AVERAGE, ESTIMATE]), 10);
    const average = parseInt(get(estimateByCategory, [AVERAGE, ESTIMATE]), 10);
    const aboveAverage = parseInt(get(estimateByCategory, [ABOVE_AVERAGE, ESTIMATE]), 10);
    const excellent = parseInt(get(estimateByCategory, [EXCELLENT, ESTIMATE]), 10);

    if ([valuation, belowAverage, average, aboveAverage, excellent].every(isFinite)) {
      return {
        valuation,
        category: {
          'below-average': belowAverage,
          average,
          'above-average': aboveAverage,
          excellent,
        },
      };
    }

    return undefined;
  } catch {
    return undefined;
  }
};

const getModsOffer = ({ partnerAppraisal, store }) => {
  if (!partnerAppraisal) {
    return undefined;
  }

  const {
    valuation,
    code,
    expiresDateUtc,
    expiresDisplayDate,
    appointmentUri,
    redemptionCertificateUri,
    atHomePickupUri,
    expressDropoffUri,
    declineReason,
    isPicsyEligible,
  } = partnerAppraisal;

  const isAppraisalCheckoutEligible = getIsAppraisalCheckoutEligible(partnerAppraisal);

  return {
    status: valuation ? 'accepted' : 'declined',
    code,
    expiresDisplayDate,
    expiresDateUtc,
    offerUrl: appointmentUri,
    printUrl: redemptionCertificateUri,
    valuation,
    store,
    isAppraisalCheckoutEligible,
    atHomePickupUrl: atHomePickupUri || '',
    expressDropoffUrl: expressDropoffUri || '',
    estimate: getModsEstimateObject(partnerAppraisal),
    declineReason: declineReason || '',
    isPicsyEligible,
  };
};

export const transformPartnerAppraisalToModsRecord = ({
  solicitedResponse,
  partnerAppraisal,
  vehicle: inputVehicle,
  store,
  kmxConditionQuestions,
  dma,
  zipCode,
  abTestBucketing,
}) => {
  const conditionDetails = kmxConditionQuestions
    ? { kmx: kmxConditionQuestions, kmxQuotesFormat: partnerAppraisal?.conditionQuestions }
    : undefined;

  const vehicle = { ...inputVehicle };
  if (partnerAppraisal) {
    vehicle.vin = partnerAppraisal.vin;
    vehicle.mileage = partnerAppraisal.mileage;
  }

  return {
    solicitedResponse,
    conditionDetails,
    offer: getModsOffer({ partnerAppraisal, store }),
    seller: omitBy(
      {
        zipCode: partnerAppraisal?.zipCode ?? zipCode,
        dma: dma || null,
        abTestBucketing: abTestBucketing || [],
      },
      isNil
    ),
    vehicle,
  };
};

export const getIsEmoSearchEnabled = location => getQuery(location).emoSearch === 'true';

export const hasPrequalifiedPartner = partners =>
  Object.values(defaultTo(partners, {})).some(({ solicitation: { isPrequalified } }) => isPrequalified);

export const hasMultiOfferPartners = partners => !!partners?.some(partner => partner !== PARTNERS.CARMAX);

/**
 * Redirects user to MOR page with given modsId
 * @param {String} modsId Mods Record I.D.
 * @param {Boolean} isAppExtensionPage for whether or not this is the webview version of the page
 */
export const goToMultiOfferResultsPage = ({ modsId, isAppExtensionPage }) => {
  const page = getMultiOfferResultsLink({ modsId, isAppExtensionPage });
  venomHistory.push(page);
};

/**
 * Redirects user to ODP with given modsId
 * @param {String} modsId Mods Record I.D.
 * @param {Boolean} isAppExtensionPage for whether or not this is the webview version of the page
 * @param {Boolean} isChicagoBearsPromotion for whether or not to append ?promotions=chicagobears to the url
 */
export const goToOfferDetailsPage = ({ modsId, isAppExtensionPage, isChicagoBearsPromotion = false }) => {
  let page = getOfferDetailsPageLink({ modsId, isAppExtensionPage });
  if (isChicagoBearsPromotion) page += '?promotions=chicagobears';
  venomHistory.push(page);
};
