import { getModelState } from 'client/data/luckdragon/model';
import { ModelPreloader } from 'client/data/luckdragon/redux/model-preloader';
import { MultiOfferModel, offersResultsPath } from 'client/data/models/multi-offer';
import { MULTI_OFFER_RESULTS } from 'site-modules/multi-offer/constants/render-types';
import { BRANDS } from 'site-modules/multi-offer/constants/brands';
import { HTTP_NOT_FOUND } from 'client/utils/http-status';
import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';

/**
 * All of EMO's page paths for routes.
 *
 * NOTE: Currently OFD path only supports "CarMax".
 */
const PATHS = {
  MOR: '/sell-car/offers/:modsId',
  MOR_APP: '/sell-car/offers/:modsId/index.app',
  OFD: '/sell-car/offer/carmax/:modsId',
  OFD_APP: '/sell-car/offer/carmax/:modsId/index.app',
  CPC: '/sell-car/offer/carmax/:modsId/printcert',
};
const exact = true;

const notFound = {
  path: Object.values(PATHS),
  exact,
  page: getLoadablePage(() =>
    import(/* webpackChunkName: "offers_not_found" */ '../pages/not-found/offers-not-found-definition')
  ),
  chunkName: 'offers_not_found',
  status: HTTP_NOT_FOUND,
};

const isOnlyCarmaxOffer = offersResults => {
  const renderType = offersResults?.renderType;
  const offers = offersResults?.offers;

  if (renderType === MULTI_OFFER_RESULTS.PENDING) {
    // If any offers are still pending
    return false;
  }

  const isOneOffer = offers?.length === 1;
  return isOneOffer && offers[0]?.brand === BRANDS.KMX;
};

const getPreloadAndPageForMorRoute = (odpImportFn, morImportFn) => ({
  preload: (params, store) => {
    const preloader = new ModelPreloader(store);

    return preloader.load().then(() => {
      const modsId = params.modsId;
      const offerResultsPath = offersResultsPath.build(modsId);
      preloader.resolve(offerResultsPath, MultiOfferModel);

      return preloader.load();
    });
  },
  page: getLoadablePage((params, store) => {
    const modelState = getModelState(store.getState());

    const modsId = params.modsId;
    const offerResultsPath = offersResultsPath.build(modsId);
    const offersResults = modelState.get(offerResultsPath, MultiOfferModel);

    if (isOnlyCarmaxOffer(offersResults)) {
      return odpImportFn();
    }

    return morImportFn();
  }),
});

const indexAppRoutes = [
  {
    path: PATHS.MOR_APP,
    exact: true,
    ...getPreloadAndPageForMorRoute(
      () =>
        import(/* webpackChunkName: "offer_details_page_app" */ '../pages/offer-details/offer-details-definition-app'),
      () =>
        import(/* webpackChunkName: "multi_offer_results_app" */ '../pages/multi-offer-results/multi-offer-results-definition-app')
    ),
  },
  {
    path: PATHS.OFD_APP,
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "offer_details_page_app" */ '../pages/offer-details/offer-details-definition-app')
    ),
    chunkName: 'offer_details_page_app',
    enableLooseEtag: true,
  },
];

const routes = [
  {
    path: PATHS.MOR,
    exact,
    ...getPreloadAndPageForMorRoute(
      () => import(/* webpackChunkName: "offer_details_page" */ '../pages/offer-details/offer-details-definition'),
      () =>
        import(/* webpackChunkName: "multi_offer_results" */ '../pages/multi-offer-results/multi-offer-results-definition')
    ),
  },
  {
    path: PATHS.OFD,
    exact,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "offer_details_page" */ '../pages/offer-details/offer-details-definition')
    ),
    chunkName: 'offer_details_page',
    enableLooseEtag: true,
  },
  {
    path: PATHS.CPC,
    exact,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "offer_print_certificate" */ '../pages/carmax-print-certificate/carmax-print-certificate-definition')
    ),
    chunkName: 'offer_print_certificate',
  },
];

export { routes, notFound, indexAppRoutes };
